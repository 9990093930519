@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: "satoshi", "arial", sans-serif;
}

/* Root settings. */
:root {
  --fc-small-font-size: 12px;
}

/* Full Calendar Table Time Column */
#root > div > section > div > main > section:nth-child(10) > div > section > div > section.overflow-hidden.lg\:col-span-6 > div > div > div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid > td > div > div > div > div.fc-timegrid-slots > table > tbody{
  font-size:0.8em;
}

/* Full Calendar Table Row Header */
#root > div > section > div > main > section:nth-child(10) > div > section > div > section.overflow-hidden.lg\:col-span-6 > div > div > div.fc-view-harness.fc-view-harness-active > div > table > thead > tr > th > div > div > table > thead > tr {
  font-size:0.8em;
}

/* Full Calendar Table Row Header: Out-of Office */
#root > div > section > div > main > section:nth-child(10) > div > section > div > section.overflow-hidden.lg\:col-span-6 > div > div > div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr:nth-child(1) > td > div > div > div > table > tbody > tr {
  font-size:0.8em;
  font: black;
}

/* "Out of Office" cell */
#root > div > section > div > main > section:nth-child(10) > div > section > div > section.overflow-hidden.lg\:col-span-6 > div > div > div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr:nth-child(1) > td > div > div > div > table > tbody > tr > td.fc-timegrid-axis.fc-scrollgrid-shrink > div > span {
  color: black;
  z-index: 1;
  padding: 10px;
}

/* Change the hover cursor to pointer for all events. */
.fc-event {
  cursor:pointer;
}

/* Google Login Button */
#google_button {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
/* Microsft Login Button */
#microsoft_button {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
